<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    :disabled="!$auth.check({ diagnoses: 'edit' }) || noEdit"
  >
    <template #activator="{ on }">
      <v-chip
        label
        small
        class="mr-1 mb-1"
        :color="highlightUnclassified && !group ? 'brown lighten-1' : stringColor(category)"
        :dark="highlightUnclassified && !group"
        v-on="on"
      >
        <v-icon
          v-if="highlightUnclassified && !group"
          small
          class="ml-n1 mr-2"
        >
          fa-circle-star fa-beat
        </v-icon>
        {{ diagnosis ? (category + '.' + diagnosis) : icd10 }}
        <span v-if="!tooltip">: {{ text }}</span>
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        {{ icd10 }}
      </v-card-title>
      <v-card-subtitle>
        {{ text }}
      </v-card-subtitle>
      <v-card-text>
        <v-autocomplete
          v-model="group"
          label="Diagnosis Group"
          :items="items"
          item-text="name"
          item-value="id"
          menu-props="offsetY"
          prepend-icon="fal fa-diagnoses"
          no-data-text="No matching groups found"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          text
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="updateCode"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {
      diagnosis: {
        type: [ Number, String ],
        default: null,
      },
      category: {
        type: String,
        default: null,
      },
      icd10: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      group: {
        type: Number,
        default: null,
      },
      tooltip: {
        type: Boolean,
        default: true,
      },
      highlightUnclassified: {
        type: Boolean,
        default: false,
      },
      noEdit: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      dialog: false,
      items: [],
    }),
    mounted () {
      this.axios.get('admin/diagnoses/groups')
        .then((response) => {
          this.items = response.data
        })
        .catch(error => {
          this.$toast.error(error)
        })
    },
    methods: {
      updateCode () {
        this.axios.patch(`admin/diagnoses/${this.icd10}`, {
          group_id: this.group,
        })
          .then(() => {
            this.dialog = false
          })
          .catch(error => {
            this.$toast.error(error)
          })
      },
    },
  }
</script>
